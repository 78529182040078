import axios from "axios";

export default class Api {
  static globalAppId = null;

  static templateConnectorString(string, connectors) {
    const regex = /{(.*?)}/g;
    let results = null;
    let templatedString = string;

    while ((results = regex.exec(string)) !== null) {
      const match = results[0];
      const connectorInfo = results[1];
      const [connector, property] = connectorInfo.split(":");
      if (connectors[connector]?.data[property]) {
        const value = connectors[connector].data[property];
        templatedString = templatedString.replace(match, value);
      }
    }

    return templatedString;
  }

  static formatData(data, connectors) {
    const dataCopy = Object.assign({}, data);

    Object.keys(dataCopy).forEach((key) => {
      if (typeof dataCopy[key] === "string") {
        dataCopy[key] = Api.templateConnectorString(dataCopy[key], connectors);
      }
    });

    return dataCopy;
  }

  static getHeaders({ auth, appId, programId }, connectors) {
    const headers = {};

    switch (auth) {
      case "ubiconnect":
        if (programId) headers["Ubi-ProgramId"] = programId;

        if (Api.globalAppId) headers["Ubi-AppId"] = Api.globalAppId;
        if (appId) headers["Ubi-AppId"] = appId;

        headers["X-Forwarded-For"] = "";
        headers.Authorization = `Ubi_v1 t=${connectors.ubiconnect.data.ticket}`;
        headers["Ubi-SessionId"] = connectors.ubiconnect.data.sessionId;
        break;
      default:
        break;
    }

    return headers;
  }

  static async request(state, { apiInfo, data }) {
    const url = Api.templateConnectorString(apiInfo.url, state.connector);
    const headers = Object.assign(
      {},
      Api.getHeaders(apiInfo, state.connector),
      apiInfo.headers
    );
    const axiosOptions = {
      url,
      method: apiInfo.method,
      headers,
    };

    if (data) {
      axiosOptions.data = Api.formatData(data, state.connector);
    }

    return axios(axiosOptions);
  }
}
