import Logger from "./logger";

class AdobeAnalytics {
  static adobeAnalyticsPageEventFired = false;
  static sCodeIsReady = false;
  static trackingEventsQueue = [];
  static pageEventsQueue = [];

  /**
   * Adds event to be tracked by adobe analytics
   * @param {eventType : 'pageevent' | 'clickevent' | 'impressionevent', event: any} trackingItem event to be tracked. Refer to type ITrackingEvent type in mooncake-gateway-core for event type
   */
  static trackEvent = (trackingItem) => {
    if (!AdobeAnalytics.adobeAnalyticsPageEventFired) {
      if (trackingItem.eventType === "pageevent") {
        AdobeAnalytics.pageEventsQueue.push(trackingItem);
        if (AdobeAnalytics.sCodeIsReady) {
          AdobeAnalytics.processEvents(AdobeAnalytics.pageEventsQueue);
          AdobeAnalytics.adobeAnalyticsPageEventFired = true;
        }
      } else {
        AdobeAnalytics.trackingEventsQueue.push(trackingItem);
      }
    } else {
      AdobeAnalytics.adobeTrack(trackingItem);
    }
  };

  /**
   * Initializes the class by adding event listeners to the appropriate tracking events
   */
  static initialize = () => {
    window.addEventListener("message", AdobeAnalytics.eventListener);
  };

  static processEvents = (events) => {
    events.forEach((pageEvent) => {
      AdobeAnalytics.adobeTrack(pageEvent);
    });
  };

  static adobeTrack = ({ event, eventType }) => {
    if (!window.adobeAnalytics) {
      Logger.debug(`Tracking ${eventType} ${JSON.stringify(event)}`);
    } else {
      window.adobeAnalytics.track(eventType, event);
    }
  };

  /**
   *  This will react to events happening with AdobeAnalytics and process the queues accordingly
   */
  static eventListener = (event) => {
    if (event.data === "S_CODE_READY") {
      AdobeAnalytics.sCodeIsReady = true;
      if (AdobeAnalytics.pageEventsQueue.length > 0) {
        AdobeAnalytics.processEvents(AdobeAnalytics.pageEventsQueue);
        AdobeAnalytics.adobeAnalyticsPageEventFired = true;
      }
    }
    if (event.data === "PAGEVIEW_EVENT_FIRED") {
      AdobeAnalytics.adobeAnalyticsPageEventFired = true;
      if (AdobeAnalytics.trackingEventsQueue.length > 0) {
        AdobeAnalytics.processEvents(AdobeAnalytics.trackingEventsQueue);
      }
      window.removeEventListener("message", AdobeAnalytics.eventListener);
    }
  };
}
export default AdobeAnalytics;
