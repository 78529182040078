import { MOONCAKE_DATA_API_RESPONSE } from "@mooncake/mooncake-gateway-core/constants";
import { prepareCustomEvent } from "@mooncake/mooncake-gateway-core/utils";
import Logger from "../../utils/logger";

class Emitter {
  constructor() {
    this.emit = this.emit.bind(this);
    this.setup = this.setup.bind(this);
  }

  emit(data, event = MOONCAKE_DATA_API_RESPONSE) {
    Logger.debug(`Emitting event : ${event}`);

    const evt = prepareCustomEvent(event, data);
    Logger.debug(evt);

    if (this.node) {
      this.node.dispatchEvent(evt);
    }
  }
  setup(mountNode) {
    this.node = mountNode;
  }
}

export default Emitter;
