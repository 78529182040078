import { name } from "../../package.json";

export default class Logger {
  static DEBUG = false;

  static error(message) {
    console.error(Logger.formatMessage(message));
  }

  static debug(message) {
    if (Logger.DEBUG) {
      if (message instanceof Object) {
        const baseMessage = Logger.formatMessage("");
        console.log(baseMessage, message);
      } else {
        console.log(Logger.formatMessage(message));
      }
    }
  }

  static formatMessage(message) {
    const time = new Date().toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return `[${name}](${time}) -- ${message}`;
  }
}
