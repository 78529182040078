import { MOONCAKE_DATA_VARIABLE_UPDATED } from "@mooncake/mooncake-gateway-core/constants";
import { createAction } from "@reduxjs/toolkit";
import { put, takeEvery, takeLeading } from "redux-saga/effects";
import get from "lodash/get";
import App from "../../App";
import { POLL_REQUEST_KEY } from "../../constants";
import Logger from "../../utils/logger";

export class PollVariables {
  static registeredVariablesPolls = {};
  static queue = [];
  static loop = null;
  static requestVariable = createAction("VARIABLE_REQUEST");
}

export function* processCheck() {
  const nbMaxAttempts = 50;
  if (!PollVariables.queue.length && PollVariables.loop) {
    clearInterval(PollVariables.loop);
    PollVariables.loop = null;
  }
  if (PollVariables.queue.length && !PollVariables.loop) {
    PollVariables.loop = yield setInterval(() => {
      PollVariables.queue = PollVariables.queue.filter((variableToCheck) => {
        variableToCheck.nbAttempts += 1;
        const { variableName, nbAttempts } = variableToCheck;
        const variableFoundValue = get(window, variableName);
        if (nbAttempts >= nbMaxAttempts || variableFoundValue !== undefined) {
          const data = {
            variableName,
            value: variableFoundValue,
          };
          if (variableFoundValue !== undefined) {
            Logger.debug(`Window variable ${variableName} is available`);
          } else {
            Logger.error(`Window variable ${variableName} not found`);
          }
          App.emitter.emit(data, MOONCAKE_DATA_VARIABLE_UPDATED(variableName));
          return false;
        }
        return true;
      });
      if (!PollVariables.queue.length) {
        clearInterval(PollVariables.loop);
        PollVariables.loop = null;
      }
    }, 200);
  }
}

export function* checkWindowVariableAvailability(action) {
  const variableName = action.payload;
  const actionKey = POLL_REQUEST_KEY(variableName);

  const variableFoundValue = get(window, variableName);

  if (variableFoundValue) {
    Logger.debug(`Window variable ${variableName} is available`);
    const data = {
      variableName,
      value: variableFoundValue,
    };
    App.emitter.emit(data, MOONCAKE_DATA_VARIABLE_UPDATED(variableName));
  } else if (PollVariables.registeredVariablesPolls[actionKey] === undefined) {
    Logger.debug(
      `Missing window variable ${variableName} - Checking availability`
    );
    PollVariables.queue.push({
      variableName,
      nbAttempts: 0,
    });
    PollVariables.registeredVariablesPolls[actionKey] = createAction(actionKey);
    yield takeLeading(
      PollVariables.registeredVariablesPolls[actionKey],
      processCheck
    );
    yield put(PollVariables.registeredVariablesPolls[actionKey]());
  }
}

export default function* () {
  if (PollVariables && PollVariables.requestVariable) {
    yield takeEvery(
      PollVariables.requestVariable.type,
      checkWindowVariableAvailability
    );
  }
}
