import { createSlice } from "@reduxjs/toolkit";
import Logger from "../../utils/logger";

export const initialState = {
  ubiconnect: {
    data: {},
    connected: false,
  },
  sfcc: {
    data: {},
    connected: false,
  },
  algolia: {
    data: {},
    connected: false,
  },
};

const connectorSlice = createSlice({
  name: "connector",
  initialState,
  reducers: {
    storeUbiConnect(state, action) {
      Logger.debug("Updating UbiConnect infos");
      state.ubiconnect = {
        connected: true,
        data: action.payload,
      };
    },
    storeSFCC(state, action) {
      Logger.debug("Updating SFCC infos");
      state.sfcc = {
        connected: true,
        data: action.payload,
      };
    },
    storeAlgolia(state, action) {
      Logger.debug("Updating Algolia infos");
      state.algolia = {
        connected: true,
        data: action.payload,
      };
    },
  },
});

export const {
  storeUbiConnect,
  storeSFCC,
  storeAlgolia,
} = connectorSlice.actions;

export default connectorSlice.reducer;
