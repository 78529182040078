import packageJson from "../package.json";

import App from "./App";

export default class MicroElement extends HTMLElement {
  constructor() {
    super();
    this.observer = new MutationObserver((mutationsList) =>
      this.update(mutationsList)
    );
    this.observer.observe(this, { attributes: true, attributeOldValue: true });
  }

  connectedCallback() {
    this._innerHTML = this.innerHTML;
    this.mount();
  }

  disconnectedCallback() {
    this.observer.disconnect();
  }

  update(mutationsList) {
    if (
      mutationsList &&
      mutationsList.some((mutation) => mutation.type === "attributes")
    ) {
      const props = MicroElement.getProps(this.attributes, {});
      this.app.updateAttributes(props);
    }
  }

  mount() {
    if (document.getElementsByTagName(packageJson.name).length > 1) {
      this.remove();
      return false;
    }

    const props = MicroElement.getProps(this.attributes);

    this.id = packageJson.name;

    const app = new App(props);
    this.app = app;
    if (process.env.NODE_ENV !== "production") {
      // ========================================================
      // DEVELOPMENT STAGE! HOT MODULE REPLACE ACTIVATION!
      // ========================================================
      const devRender = () => {
        app.render(this);
      };

      // Wrap render in try/catch
      try {
        devRender();
      } catch (error) {
        console.error(error);
      }
    } else {
      // ========================================================
      // PRODUCTION GO!
      // ========================================================
      app.render(this);
    }
  }

  static getProps(attributes, propTypes) {
    const types = propTypes || {};

    return Array.prototype.slice
      .call(attributes)
      .filter((attr) => attr.name !== "style")
      .map((attr) => MicroElement.convert(types, attr.name, attr.value))
      .reduce(
        (props, prop) => ({
          ...props,
          [prop.name]: prop.value,
        }),
        {}
      );
  }

  static convert(propTypes, attrName, attrValue) {
    const propNames = Object.keys(propTypes).filter(
      (key) => key.toLowerCase() === attrName
    );
    const propName = propNames.length ? propNames[0] : attrName;
    let value = attrValue;

    if (attrValue === "true" || attrValue === "false") {
      value = attrValue === "true";
    } else if (parseFloat(attrValue) === +attrValue && attrValue !== "") {
      value = +attrValue;
    } else if (/^[[{].*[}\]]$/.exec(attrValue)) {
      value = JSON.parse(attrValue);
    }

    return {
      name: propName,
      value,
    };
  }
}

customElements.define(packageJson.name, MicroElement);
