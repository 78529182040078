import { createSlice } from "@reduxjs/toolkit";
import Logger from "../../utils/logger";

const dataSlice = createSlice({
  name: "data",
  initialState: {},
  reducers: {
    storeData(state, action) {
      Logger.debug("Updating data state");
      const { response, key } = action.payload;
      state[key] = response.resource;
    },
  },
});

export const { storeData } = dataSlice.actions;

export default dataSlice.reducer;
