import { MOONCAKE_DATA_UBICONNECT_FETCHED } from "@mooncake/mooncake-gateway-core/constants";
import UbiConnect from "./connectors/ubiconnect";
import SFCC from "./connectors/sfcc";
import Emitter from "./core/emitter";
import Receiver from "./core/receiver";
import { storeAlgolia, storeSFCC, storeUbiConnect } from "./features/connector";
import store from "./store";
import Logger from "./utils/logger";
import Api from "./utils/api";
import AlgoliaApi from "./utils/algoliaApi";
import AdobeAnalytics from "./utils/AdobeAnalytics";

export default class App {
  static emitter = new Emitter();
  static receiver;

  constructor(props) {
    this.emitter = App.emitter;
    this.receiver = new Receiver();
    this.props = props;

    if (this.props.debug) {
      Logger.DEBUG = true;
    }

    if (this.props.appid) {
      Api.globalAppId = this.props.appid;
    }

    this.setupConnectors();

    Logger.debug("mooncake-data initialized");
  }

  setupConnectors() {
    AdobeAnalytics.initialize();
    if (this.props.ubiconnect) {
      UbiConnect().then((infos) => {
        store.dispatch(storeUbiConnect(infos));
        this.emitter.emit(infos, MOONCAKE_DATA_UBICONNECT_FETCHED);
      });
    }
    if (this.props.sfcc) {
      SFCC(store).then(({ storeCode, locale, sitePrefs }) => {
        store.dispatch(
          storeSFCC({
            storeCode,
            locale,
            sitePrefs,
          })
        );
        const algoliaApiKey = this.props["algolia-api-key"];
        const algoliaAppId = this.props["algolia-app-id"];
        const algoliaEnv = this.props["algolia-env"];
        const algoliaIndexName = this.props["algolia-index-name"];
        const algoliaIndexConfigPath = this.props["algolia-index-config-path"];

        if (algoliaApiKey && algoliaAppId) {
          AlgoliaApi.initialize({
            locale,
            sitePrefs,
            storeCode,
            apiKey: algoliaApiKey,
            apiId: algoliaAppId,
            indexConfigPath: algoliaIndexConfigPath,
            env: algoliaEnv,
            indexName: algoliaIndexName,
          });
          store.dispatch(
            storeAlgolia({
              algoliaApiKey,
              algoliaAppId,
              algoliaIndexConfigPath,
              algoliaEnv,
              algoliaIndexName,
            })
          );
        }
      });
    }
  }

  render(node) {
    this.receiver.setup();
    this.emitter.setup(node);
  }

  updateAttributes(props) {
    Logger.debug("mooncake-data props updated");
    Logger.debug("old props : ");
    Logger.debug(this.props);
    Logger.debug("new props : ");
    Logger.debug(props);

    if (props.appid !== this.props.appid) {
      Api.globalAppId = props.appid;
    }

    if (props.debug !== this.props.debug) {
      Logger.DEBUG = props.debug;
    }

    this.props = props;
  }
}
