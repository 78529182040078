import {
  MOONCAKE_DATA_API_REQUEST,
  MOONCAKE_DATA_VARIABLE_REQUEST,
  MOONCAKE_DATA_TRACKING_REQUEST,
} from "@mooncake/mooncake-gateway-core/constants";
import { ApiRequestData } from "../../sagas/apiRequest";
import { PollVariables } from "../../sagas/pollVariables";
import store from "../../store";
import Logger from "../../utils/logger";
import AdobeAnalytics from "../../utils/AdobeAnalytics";

class Receiver {
  supportedEvents = {
    [MOONCAKE_DATA_API_REQUEST]: ApiRequestData.requestApi,
    [MOONCAKE_DATA_VARIABLE_REQUEST]: PollVariables.requestVariable,
    [MOONCAKE_DATA_TRACKING_REQUEST]: null,
  };

  constructor() {
    this.registerEvents = this.registerEvents.bind(this);
    this.setup = this.setup.bind(this);
  }

  registerEvents() {
    Object.keys(this.supportedEvents).forEach((supportedEventsKey) => {
      Logger.debug(`Registering event ${supportedEventsKey}`);
      document.addEventListener(supportedEventsKey, (e) => {
        Logger.debug("Event received ", supportedEventsKey);
        if (e.type === MOONCAKE_DATA_TRACKING_REQUEST) {
          AdobeAnalytics.trackEvent(e.detail);
        } else {
          store.dispatch(this.supportedEvents[supportedEventsKey](e.detail));
        }
      });
    });
  }
  setup() {
    this.registerEvents();
  }
}

export default Receiver;
