import { MOONCAKE_DATA_RESOURCE_UPDATED } from "@mooncake/mooncake-gateway-core/constants";
import { call, put, takeEvery, select, takeLeading } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";
import { all } from "@redux-saga/core/effects";
import Api from "../../utils/api";
import { storeData } from "../../features/data";
import { storeUbiConnect } from "../../features/connector";
import Logger from "../../utils/logger";
import App from "../../App";
import { API_REQUEST_KEY, NOT_LOGGED_IN_ERROR } from "../../constants";
import AlgoliaApi from "../../utils/algoliaApi";

export class ApiRequestData {
  static registeredCalls = {};
  static queue = [];
  static requestApi = createAction("API_REQUEST");
}

export function* processApiRequest(action) {
  Logger.debug(`Processing api request: ${action.payload.apiInfo.url}`);

  const state = yield select();
  const key = action.type;
  const { apiInfo, resourceType } = action.payload;

  let canProcessRequest = true;
  if (
    apiInfo.auth &&
    apiInfo.connectors?.length > 0 &&
    state.connector[apiInfo.auth]
  ) {
    const connectorToCheck = state.connector[apiInfo.auth];
    if (
      typeof connectorToCheck.data.isLoggedIn !== "undefined" &&
      !connectorToCheck.data.isLoggedIn
    ) {
      canProcessRequest = false;
    }
  }

  const response = { apiInfo, resourceType };

  if (!apiInfo.refresh && state.data[key] !== undefined) {
    response.resource = state.data[key];
    App.emitter.emit(response);
  } else {
    try {
      if (!canProcessRequest) {
        throw new Error(NOT_LOGGED_IN_ERROR);
      }

      let fetchReponse;

      if (apiInfo.url.indexOf("algolia:") !== -1) {
        fetchReponse = yield call(AlgoliaApi.fetchProducts, action.payload);
      } else {
        fetchReponse = yield call(Api.request, state, action.payload);
      }

      const { data, status } = fetchReponse;

      response.resource = data;
      response.status = status;

      App.emitter.emit(response);
      App.emitter.emit(response, MOONCAKE_DATA_RESOURCE_UPDATED(resourceType));

      if (apiInfo.storeResponse) {
        yield put(storeData({ response, key }));
      }
    } catch (e) {
      if (e.message === NOT_LOGGED_IN_ERROR) {
        Logger.debug(e);
      } else {
        Logger.error(e);
      }
      response.error = e;
      App.emitter.emit(response);
    }
  }
}

export function* processQueue() {
  Logger.debug("Processing queue");
  const selfConnectors = yield select(
    /* istanbul ignore next */ (store) => store.connector
  );
  const connectedCalls = [];
  ApiRequestData.queue.forEach((apiRequest, index) => {
    const { requiredConnectors, request } = apiRequest;
    const connectedConnectors = requiredConnectors.map(
      (connector) => selfConnectors[connector].connected
    );

    if (connectedConnectors.every((connector) => connector === true)) {
      const { url } = request.apiInfo;
      const requestUrl = Api.templateConnectorString(url, selfConnectors);
      const key = API_REQUEST_KEY(requestUrl);

      Logger.debug(`Api request processing - ${key}`);

      if (ApiRequestData.registeredCalls[key] === undefined) {
        Logger.debug(`Registering url: ${requestUrl}`);
        ApiRequestData.registeredCalls[key] = createAction(key);
        connectedCalls.push(
          takeLeading(ApiRequestData.registeredCalls[key], processApiRequest)
        );
      }

      connectedCalls.push(put(ApiRequestData.registeredCalls[key](request)));
      ApiRequestData.queue.splice(index, 1);
    }
  });

  yield all(connectedCalls);
}

export function* parseApiRequest(action) {
  try {
    const { apiInfo } = action.payload;
    const { url } = apiInfo;
    const key = API_REQUEST_KEY(url);
    const selfConnectors = yield select(
      /* istanbul ignore next */ (store) => store.connector
    );

    let requiredConnectors = [];
    let allConnectorsConnected = false;

    if (apiInfo.connectors || apiInfo.auth) {
      if (apiInfo.connectors) {
        requiredConnectors = apiInfo.connectors.slice();
      }
      if (apiInfo.auth) {
        requiredConnectors.push(apiInfo.auth);
      }

      const connectedConnectors = requiredConnectors.map(
        (connector) => selfConnectors[connector].connected
      );
      allConnectorsConnected = connectedConnectors.every(
        (connector) => connector === true
      );
    } else {
      allConnectorsConnected = true;
    }

    if (!allConnectorsConnected) {
      Logger.debug(`Missing connectors for ${key} - Adding to queue`);
      ApiRequestData.queue.push({
        requiredConnectors,
        request: action.payload,
      });

      // Add new connectors in the array for automatic queue processing
      yield all([takeEvery(storeUbiConnect.type, processQueue)]);
    } else {
      // All core are available
      const requestUrl = Api.templateConnectorString(url, selfConnectors);
      const formattedKey = API_REQUEST_KEY(requestUrl);

      if (ApiRequestData.registeredCalls[formattedKey] === undefined) {
        Logger.debug(`Registering url: ${requestUrl}`);
        ApiRequestData.registeredCalls[formattedKey] = createAction(key);

        yield takeLeading(
          ApiRequestData.registeredCalls[formattedKey],
          processApiRequest
        );
      }

      Logger.debug(`Firing request : ${requestUrl}`);

      yield put(ApiRequestData.registeredCalls[formattedKey](action.payload));
    }
  } catch (e) {
    Logger.error(e);
  }
}

export default function* () {
  if (ApiRequestData && ApiRequestData.requestApi) {
    yield takeEvery(ApiRequestData.requestApi.type, parseApiRequest);
  }
}
