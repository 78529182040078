import Logger from "../utils/logger";

export default () =>
  new Promise((resolve) => {
    const connectSdkLoaded = (infos) => {
      const { ticket, sessionId, userId } = infos;
      const isLoggedIn = !!(ticket && sessionId && userId);
      Logger.debug("UbiConnect Loaded");
      resolve({ ticket, sessionId, userId, isLoggedIn });
    };

    if (window?.Connect?._sdk) {
      window.Connect._sdk.getTicket().subscribe(({ payload }) => {
        connectSdkLoaded(payload);
      });
    } else {
      document.addEventListener("CONNECT_SDK_LOADED", (e) =>
        connectSdkLoaded(e.detail)
      );
    }
  });
