import algoliasearch from "algoliasearch";
import { chunk, get } from "lodash";
import Logger from "./logger";

class AlgoliaApi {
  static client = null;
  static storeIndex = null;
  static index = null;

  static initialize = ({
    storeCode,
    locale,
    sitePrefs,
    apiKey,
    apiId,
    indexConfigPath,
    env = "production",
    indexName = "custom_MFE",
  } = {}) => {
    AlgoliaApi.client = algoliasearch(apiId, apiKey);
    AlgoliaApi.storeIndex = `${env}__${storeCode}_ubisoft__products__${locale}__${indexName}`;

    if (indexConfigPath) {
      const config = get(sitePrefs, indexConfigPath);
      if (config) {
        try {
          const JSONConfig = JSON.parse(config);
          let algoliaLocaleCode = locale ?? "default";

          // locale code received from GeoRestrictedResources does not match Algolia locales for BR store
          // Replace "en" with "ia" and "es" with "la"
          if (storeCode === "br") {
            algoliaLocaleCode = algoliaLocaleCode
              .replace("en", "ia")
              .replace("es", "la");
          }

          // Get the config for the current locale, default to parent locale or default locale if necessary
          const localeConfig =
            JSONConfig[algoliaLocaleCode] ??
            JSONConfig[algoliaLocaleCode.split("_")[0]] ??
            JSONConfig.default;

          // Get the index defined in algolia-index-name, default to defaultIndex if necessary
          AlgoliaApi.storeIndex =
            localeConfig[indexName] ?? localeConfig.defaultIndex;
        } catch (error) {
          Logger.debug(
            `Unable to parse Algolia index config from Site Preference defined in algolia-index-config-path. Defaulting to ${AlgoliaApi.storeIndex}`
          );
        }
      } else {
        Logger.debug(
          `Unable to find Site Preference defined in algolia-index-config-path. Defaulting to ${AlgoliaApi.storeIndex}`
        );
      }
    }

    AlgoliaApi.index = AlgoliaApi.client.initIndex(AlgoliaApi.storeIndex);
  };

  static getRemainingIds = (ids, products) => {
    const storedIds = products.reduce((acc, cur) => [...acc, cur.id], []);
    return ids.filter((id) => {
      return storedIds.indexOf(id) < 0;
    });
  };

  static chunkProductIds = (ids, maxQueryString) => {
    return chunk(
      ids,
      Math.floor(maxQueryString / ids[0].toString().length - 1)
    );
  };

  static fetchMasters = async (ids, maxQueryString, algoliaConfig = {}) => {
    const chunkedIds = AlgoliaApi.chunkProductIds(ids, maxQueryString);

    const promises = [];

    for (let index = 0; index < chunkedIds.length; index++) {
      const element = chunkedIds[index];
      promises.push(
        AlgoliaApi.index.search(element.join(","), {
          ...algoliaConfig,
          removeWordsIfNoResults: "allOptional",
          hitsPerPage: element.length,
          typoTolerance: false,
        })
      );
    }

    const responses = await Promise.all(promises);
    return responses.reduce((acc, cur) => {
      if (!cur.hits) return null;

      return [...acc, ...cur.hits];
    }, []);
  };

  static fetchVariants = async (ids) => {
    const response = await AlgoliaApi.index.getObjects(ids);

    return response.results.filter((product) => product !== null);
  };

  static fetchBothTypeProducts = async (ids, algoliaConfig = {}) => {
    const variantProducts = await AlgoliaApi.fetchVariants(ids);
    const remainingIds = AlgoliaApi.getRemainingIds(ids, variantProducts);
    const masterProducts =
      remainingIds.length > 0
        ? await AlgoliaApi.fetchMasters(remainingIds, 512, algoliaConfig)
        : [];

    return [...masterProducts, ...variantProducts];
  };

  static fetchProducts = async ({ apiInfo }) => {
    const { url, algoliaConfig } = apiInfo;
    const [, productType, productList] = url.split("::");
    const ids = productList.split(",");

    if (
      productType !== "master" &&
      productType !== "variant" &&
      productType !== "both"
    ) {
      Logger.error(`${productType} not valid in api url: ${url}`);
      return {
        data: [],
        status: 404,
      };
    }

    let products;

    if (productType === "master") {
      products = await AlgoliaApi.fetchMasters(ids, 512, algoliaConfig);
    } else if (productType === "variant") {
      products = await AlgoliaApi.fetchVariants(ids, algoliaConfig);
    } else if (productType === "both") {
      products = await AlgoliaApi.fetchBothTypeProducts(ids, algoliaConfig);
    }

    return {
      data: products,
      status: 200,
    };
  };
}

export default AlgoliaApi;
