import { MOONCAKE_DATA_VARIABLE_UPDATED } from "@mooncake/mooncake-gateway-core/constants";
import get from "lodash/get";
import Logger from "../utils/logger";
// eslint-disable-next-line import/no-cycle
import { PollVariables } from "../sagas/pollVariables";

const POLLED_VARIABLES = {
  storeCode: "GeoRestrictedResources.GEORESTRICTED_CURRENTSITE",
  locale: "GeoRestrictedResources.STOREFRONT_CURRENT_LOCALE",
  sitePrefs: "SitePreferences",
};

const polledVariableKeys = Object.keys(POLLED_VARIABLES);

const getPolledVariables = () => {
  const polledVariables = polledVariableKeys.reduce((acc, variableName) => {
    const value = get(window, POLLED_VARIABLES[variableName]);
    if (value) {
      acc[variableName] = value;
    }
    return acc;
  }, {});
  if (Object.keys(polledVariables).length === polledVariableKeys.length) {
    Logger.debug("SFCC connector loaded");
    return polledVariables;
  }
  return null;
};

export default (store) =>
  new Promise((resolve) => {
    let polledVariables = getPolledVariables();
    if (polledVariables) {
      resolve(polledVariables);
    } else {
      const pollListener = () => {
        polledVariables = getPolledVariables();
        if (polledVariables) {
          resolve(polledVariables);
          polledVariableKeys.forEach((variableName) => {
            document
              .getElementById("mooncake-data")
              .removeEventListener(
                MOONCAKE_DATA_VARIABLE_UPDATED(POLLED_VARIABLES[variableName]),
                pollListener
              );
          });
        }
      };

      polledVariableKeys.forEach((variableName) => {
        document
          .getElementById("mooncake-data")
          .addEventListener(
            MOONCAKE_DATA_VARIABLE_UPDATED(POLLED_VARIABLES[variableName]),
            pollListener
          );
        store.dispatch(
          PollVariables.requestVariable(POLLED_VARIABLES[variableName])
        );
      });
    }
  });
